#popup-root {
    .popup {
        &-overlay {
            background: rgba(0, 0, 0, 0.6);
            z-index: 9999;
        }

        &-content {
            &.main-popup-content {
                max-width: 376px;
                width: 100%;

                background: #282829;

                -webkit-border-radius: 24px;
                -moz-border-radius: 24px;
                border-radius: 24px;
                border: none;

                padding: 16px 16px 44px;
            }
            &.food-popup-content {
                max-width: 328px;

                @media only screen and (min-width: 768px) {
                    max-width: 744px;
                }

                padding: 0;

                overflow: hidden;
            }
        }
    }
}
