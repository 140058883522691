#popup-root {
    .popup {
        &-overlay {
            background: rgba(0, 0, 0, 0.6);
        }

        &-content {
            &.main-tooltip-content {
                max-width: 93%;
                width: 100%;

                background: #282829;

                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;
                border-radius: 16px;
                border: none;

                padding: 16px;

                @media only screen and (min-width: 768px) {
                    max-width: 264px;
                }
            }
        }
    }
}
