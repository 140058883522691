@font-face {
    font-family: "BebasNeue";
    src: url("BebasNeue/regular/bebas_neue_regular.eot");
    src: url("BebasNeue/regular/bebas_neue_regular.eot?#iefix")format("embedded-opentype"),
    url("BebasNeue/regular/bebas_neue_regular.woff") format("woff"),
    url("BebasNeue/regular/bebas_neue_regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "BebasNeue";
    src: url("BebasNeue/bold/bebas_neue_bold.eot");
    src: url("BebasNeue/bold/bebas_neue_bold.eot?#iefix")format("embedded-opentype"),
    url("BebasNeue/bold/bebas_neue_bold.woff") format("woff"),
    url("BebasNeue/bold/bebas_neue_bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto/regular/roboto_regular.eot");
    src: url("Roboto/regular/roboto_regular.eot?#iefix")format("embedded-opentype"),
    url("Roboto/regular/roboto_regular.woff") format("woff"),
    url("Roboto/regular/roboto_regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url("Roboto/medium/roboto_medium.eot");
    src: url("Roboto/medium/roboto_medium.eot?#iefix")format("embedded-opentype"),
    url("Roboto/medium/roboto_medium.woff") format("woff"),
    url("Roboto/medium/roboto_medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
